<template>
  <div class="material">
    <div @click="handleOpen()">
      <slot name="custom-open-button">
        <el-button class="upload-btn">
          {{ $t("productManagement.uploadClick") }}
        </el-button>
      </slot>
    </div>
    <!-- <el-dialog
      width="1024px"
      :center="true"
      :visible="dialogVisible"
      :before-close="handleClose"
      append-to-body
    > -->
    <el-drawer
      :visible="dialogVisible"
      size="1024px"
      close-on-press-escape
      :append-to-body="true"
      @close="handleClose"
    >
      <div v-show="!cropping">
        <el-container>
          <!-- 顶部 -->
          <el-header height="120px">
            <UploadImage
              v-if="type === 'image'"
              @onsuccess="handleImageUploadSuccess"
            />
            <UploadVideo
              v-else-if="type === 'video'"
              @onsuccess="handleVideoUploadSuccess"
            />
          </el-header>
          <material-list
            ref="list"
            :type="type"
            :count="count"
            @onCheck="handleCheck"
          />
        </el-container>
        <el-footer class="drawer-footer">
          <el-button
            class="opt-btn"
            type="text"
            @click="navto('/material/list')"
            >{{ $t("menu.material") }}</el-button
          >
          <el-button
            v-if="cropper"
            class="btn"
            :disabled="disabledSubmit"
            @click="handleCropping"
          >
            {{ $t("common.cut") }}
          </el-button>
          <el-button
            v-else
            class="btn"
            :disabled="disabledSubmit"
            @click="handleSubmit"
          >
            {{ $t("common.ok") }}
          </el-button>
          <el-button class="btn" @click="handleClose">
            {{ $t("common.cancel") }}
          </el-button>
        </el-footer>
      </div>
      <image-cropper-list
        v-if="cropping"
        :size="cropperSize"
        :list="iCheckMaterialList"
        @submit="handleSubmit"
      />
    </el-drawer>
    <!-- </el-dialog> -->
  </div>
</template>
<script>
import UploadImage from "@/components/UploadImage";
import UploadVideo from "@/components/UploadVideo";
import MaterialList from "@/components/MaterialList";
import ImageCropperList from "@/components/ImageCropperList";
export default {
  components: {
    UploadImage,
    UploadVideo,
    MaterialList,
    ImageCropperList,
  },
  props: {
    type: {
      require: true,
      type: String,
    },
    count: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    cropper: {
      type: Boolean,
      default: false,
    },
    cropperSize: {
      type: Object,
      default() {
        return {
          witdh: 0,
          height: 0,
        };
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      iCheckMaterialList: [],
      cropping: false,
    };
  },
  computed: {
    disabledSubmit: function () {
      return !(this.iCheckMaterialList.length > 0);
    },
  },
  methods: {
    // 开始裁切
    handleCropping() {
      console.log("handleCropping:", this.iCheckMaterialList);
      this.cropping = true;
    },
    handleCheck(list) {
      this.iCheckMaterialList = list;
    },
    handleImageUploadSuccess(e) {
      console.log("handleImageUploadSuccess:", e);
      this.$refs["list"].getList(1);
    },
    handleVideoUploadSuccess(e) {
      console.log("handleVideoUploadSuccess:", e);
      this.$refs["list"].getList(1);
    },
    // 打开弹窗
    handleOpen() {
      if (!this.disabled) {
        this.iCheckMaterialList = [];
        this.dialogVisible = true;
        this.$refs["list"] && this.$refs["list"].getList();
      }
    },
    // 关闭弹窗
    handleClose() {
      this.$refs["list"].cleanList();
      this.dialogVisible = false;
      this.cropping = false;
    },
    // 提交数据
    handleSubmit() {
      console.log("handleSubmit:", this.iCheckMaterialList);
      this.$emit(
        "receiver",
        this.count === 1 ? this.iCheckMaterialList[0] : this.iCheckMaterialList
      );
      this.handleClose();
    },
    navto(path) {
      this.$router.push({
        path,
      });
    },
  },
};
</script>

<style lang="scss">
.dialog-container {
  height: 660px;
}
.material .upload-oss {
  width: 100%;
  height: 120px;
}
.material .upload-oss .el-upload-dragger {
  width: 100%;
  height: 120px;
  margin: 0;
}
.material .upload-oss .el-upload {
  width: 100%;
  height: 120px;
}
.material .upload-oss .el-icon-upload {
  margin-top: 15px;
}
.upload-btn {
  width: 150px;
  height: 50px;
  border-radius: 6px;
  background: #ff0a0a;
  color: #fff;
}
.opt-btn {
  position: absolute;
  right: 30px;
}
.el-button.is-disabled {
  color: #c0c4cc;
  cursor: not-allowed;
  background-image: none;
  background-color: #fff;
  border-color: #ebeef5;
}
.image-wrap {
  width: 64px;
  height: 64px;
}
</style>