<template>
  <div v-loading="loading">
    <el-tabs v-model="tabsActive">
      <el-tab-pane
        v-for="(item, index) in list"
        :key="`crop-img-${index}`"
        :name="`${index}`"
      >
        <div slot="label" class="image-wrap">
          <div class="fit" v-if="item.fit">
            <i class="icon el-icon-success" />
          </div>
          <el-image
            :src="`${item.filename}?x-oss-process=style/w64`"
            class="imgbg"
            fit="contain"
          ></el-image>
        </div>
      </el-tab-pane>
    </el-tabs>

    <template v-if="list[croppingCurrentIndex].fit">
      <el-image
        :src="`${list[croppingCurrentIndex].filename}?x-oss-process=style/w405h539`"
        class="imgbg"
        fit="contain"
      ></el-image>
    </template>
    <image-cropper
      v-else
      ref="imageCropper"
      :size="size"
      :img="list[croppingCurrentIndex].filename"
    />
    <div>
      <p v-if="allFit">{{ $t("common.allimage") }}</p>
      <p v-else-if="list[croppingCurrentIndex].fit">
        {{ $t("common.anoterimage") }}
      </p>
      <p v-else>{{ $t("common.moveposition") }}</p>
    </div>
    <div style="padding: 8px">
      <el-button
        :disabled="list[croppingCurrentIndex].fit"
        type="primary"
        @click="handleCut"
      >
        {{ $t("common.cut") }}
      </el-button>
      <el-button :disabled="!allFit" type="primary" @click="handleSubmit">
        {{ $t("common.submit") }}
      </el-button>
      <!-- <el-button
      :disabled="
        !list[croppingCurrentIndex].fit || croppingCurrentIndex < list.length
      "
      @click="croppingCurrentIndex++"
      >Next</el-button
    > -->
      <!-- <p>{{ list }}</p> -->
    </div>
  </div>
</template>

<script>
import ImageCropper from "@/components/ImageCropper";
import { uploadoss } from "@/helper/api.js";
import { getFileNameMd5 } from "@/helper/ali-oss";
import { GetOssPolicyToken, AddOssFile } from "@/utils/api";

export default {
  props: {
    size: {
      type: Object,
      default() {
        return {
          witdh: 0,
          height: 0,
        };
      },
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    ImageCropper,
  },
  data() {
    return {
      loading: false,
      croppingCurrentIndex: 0,
    };
  },
  computed: {
    tabsActive: {
      get() {
        return String(this.croppingCurrentIndex);
      },
      set(newVal) {
        this.croppingCurrentIndex = Number(newVal);
      },
    },
    allFit() {
      return !this.list.some((v) => {
        console.log("allFit-fit:", v.fit);
        return !v.fit;
      });
    },
  },
  created() {
    this.list.forEach((item, index) => {
      this.checkSize(item, index);
    });
  },
  methods: {
    handleSubmit() {
      this.$emit("submit");
    },

    checkSize(item, index) {
      let image = new Image();
      image.setAttribute("crossOrigin", "anonymous");
      const fuhao = item.filename.indexOf("?") > -1 ? "&" : "?";
      image.onload = () => {
        const itemRatio = image.width / image.height;
        const sizeRatio = this.size.width / this.size.height;
        console.log(item.filename, image.width, image.height);
        console.log("itemRatio:", itemRatio);
        console.log("sizeRatio", sizeRatio);
        this.$nextTick(() => {
          if (itemRatio === sizeRatio) {
            console.log("比例完全一样");
            this.$set(this.list[index], "fit", true);
          } else if (itemRatio > sizeRatio) {
            if (itemRatio - sizeRatio < 0.01) {
              console.log("比例相差不大");
              this.$set(this.list[index], "fit", true);
            } else {
              this.$set(this.list[index], "fit", false);
            }
          } else if (sizeRatio > itemRatio) {
            if (sizeRatio - itemRatio < 0.01) {
              console.log("比例相差不大");
              this.$set(this.list[index], "fit", true);
            } else {
              this.$set(this.list[index], "fit", false);
            }
          }
          console.log("item:", item);
        });
      };
      // image.onerror = (error) => {
      // };
      image.src = item.filename + fuhao + "weilehuangcuna=" + Math.random();
    },

    handleCut() {
      this.loading = true;
      this.$refs.imageCropper.cut((res) => {
        console.log("handleCut-res:", res);
        this.handleUpload(res);
      });
    },

    // 上传事件
    async handleUpload(file) {
      const fileName = await getFileNameMd5(file);
      const type = file.type;
      const size = file.size;
      const restype = type.substring(0, type.indexOf("/"));

      const OssSignatureObj = await this.getOssSignature();
      await uploadoss("POST", OssSignatureObj.host, {
        key: fileName,
        OSSAccessKeyId: OssSignatureObj.accessid,
        policy: OssSignatureObj.policy,
        signature: OssSignatureObj.signature,
        success_action_status: 200,
        file: file,
      }).then(() => {
        AddOssFile({
          categoryId: this.storeId || "",
          filename: fileName,
          filetype: restype,
          size: size,
        }).then((res) => {
          const url = `https://oss.sokogate.com/${fileName}`;
          console.log("handleUpload-uploadRes:", res, url);
          this.loading = false;
          this.list[this.croppingCurrentIndex].fit = true;
          this.list[this.croppingCurrentIndex].filename = url;
          // this.$emit("onsuccess", { filename: url });
        });
      });
    },

    // oss签名
    getOssSignature() {
      return new Promise((resolve, reject) => {
        try {
          console.log("getOssSignature:");
          this.ossSignatureAvailable()
            .then((res) => {
              console.log("ossSignatureObj:", res);
              resolve(res);
            })
            .catch((err) => {
              console.log("ossSignatureObj-err:", err);
              GetOssPolicyToken()
                .then((res) => {
                  console.log("GetOssFileList", res);
                  localStorage.setItem(
                    "ossSignature",
                    JSON.stringify(res.data)
                  );
                  resolve(res.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
        } catch (error) {
          reject(error);
        }
      });
    },

    ossSignatureAvailable() {
      return new Promise(function (resolve, reject) {
        console.log("ossSignatureAvailable:");
        try {
          const ossSignatureJson = localStorage.getItem("ossSignature");
          console.log("ossSignatureJson:", ossSignatureJson);
          if (ossSignatureJson) {
            const ossSignatureObj = JSON.parse(ossSignatureJson);
            const nowUnix = Math.round(new Date().getTime() / 1000);
            if (
              ossSignatureObj &&
              ossSignatureObj.expire &&
              ossSignatureObj.expire > nowUnix &&
              ossSignatureObj.expire - nowUnix > 60 * 60 // 有效期大于1小时
            ) {
              resolve(ossSignatureObj);
            } else {
              reject(new Error("ossSignature expired"));
            }
          } else {
            reject(new Error("empty ossSignature"));
          }
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
</script>

<style>
.image-wrap {
  position: relative;
}
.fit {
  top: -0;
  right: -0;
  position: absolute;
  /* display: flex; */
  /* justify-content: center;
  align-items: center; */
  z-index: 1;
  /* width: 100%;
  height: 100%; */
}
.icon {
  color: #ef2e22;
  font-size: 30px;
  background-color: #fff;
  border-radius: 50%;
}
</style>