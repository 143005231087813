<template>
  <div class="upload-video">
    <el-progress
      v-if="uploading"
      :text-inside="true"
      :stroke-width="120"
      :percentage="percentage"
      :status="percentage === 100 ? 'success' : null"
    ></el-progress>
    <el-upload
      v-else
      drag
      action
      :http-request="handleUpload"
      :before-upload="getAuth"
      :show-file-list="false"
      accept="video/mp4"
      v-model="this.video"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">
        {{ $t("productManagement.uploadTips") }}
        <em>{{ $t("productManagement.uploadClick") }}</em>
      </div>
    </el-upload>
  </div>
</template>

<script>
import { Base64 } from "js-base64";
import { getFileNameMd5, vodUploader } from "@/helper/ali-oss";
// import { createData } from "@/helper/api.js";
import { GetVodeUpload } from "@/utils/api";
export default {
  props: ["type", "id", "data"],
  data() {
    return {
      vod: [],
      dialogVisible: false,

      uploading: false,
      percentage: 0,

      hiddenUploadGallery: "",
      activeIndex: "1",
      key: "1",
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageSizes: [1, 2, 3, 4, 5],
      picture: "",
      video: "",

      storeId: "",
      accessid: "",
      expire: "",
      host: "",
      policy: "",
      signature: "",
      UploadAddress: "",
      UploadAuth: {},
      VideoId: "",
    };
  },
  created() {
    this.storeId = localStorage.getItem("storeId");
  },
  methods: {
    handleProgress(percentage) {
      console.log("handleProgress:", percentage);
      if (percentage === 1) {
        this.percentage = 100;
        setTimeout(() => {
          this.uploading = false;
        }, 3000);
      } else if (percentage > 0) {
        this.uploading = true;
        this.percentage = Math.floor(percentage * 100);
      } else {
        this.uploading = false;
      }
    },

    async handleUpload(file) {
      const result = await vodUploader(
        this.UploadAddress,
        this.UploadAuth,
        this.VideoId,
        file,
        this.handleProgress
      );
      // console.log("result:", result);
      this.$emit("onsuccess", result);
    },

    async getAuth(option) {
      console.log("getAuth-option:", option);
      const fileName = await getFileNameMd5(option);
      const getParms = {
        // storeId: this.storeId,
        title: option.name,
        fileName,
      };
      console.log("getParms:", getParms);

      // await createData("POST", "vod/upload", getParms).then((res) => {
      //   console.log("res:", res);
      //   this.UploadAddress = JSON.parse(Base64.decode(res.data.UploadAddress));
      //   console.log("UploadAddress:", this.UploadAddress);
      //   this.UploadAuth = JSON.parse(Base64.decode(res.data.UploadAuth));
      //   console.log("UploadAuth:", this.UploadAuth);
      //   this.VideoId = res.data.VideoId;
      // });
      await GetVodeUpload(getParms).then((res) => {
        console.log("res:", res);
        this.UploadAddress = JSON.parse(Base64.decode(res.data.uploadAddress));
        console.log("UploadAddress:", this.UploadAddress);
        this.UploadAuth = JSON.parse(Base64.decode(res.data.uploadAuth));
        console.log("UploadAuth:", this.UploadAuth);
        this.VideoId = res.data.videoId;
      });
    },
  },
};
</script>

<style lang="scss">
.upload-video {
  .el-upload.el-upload--text {
    width: 100%;
    height: 120px;
    .el-upload-dragger {
      width: 100%;
      height: 120px;

      .el-icon-upload {
        margin-top: 15px;
      }
    }
  }
}
div .el-progress-bar__outer,
div .el-progress-bar__inner {
  border-radius: 0;
  text-align: center;
}
div .el-progress-bar__innerText {
  font-size: 30px;
}
</style>
